import chevronDownIcon1 from "assets/images/icons/chevron-down-icon1.svg";
import HamburgerIcon from "assets/images/icons/menu2.png";
import Aero from "assets/images/aerostatRender/aero5.webp";
import Compass from "assets/images/logo/compass.svg";
import Test from "assets/images/testimages/test.webp";
import TestImage1 from "assets/images/testimages/testImage.webp";
import TestImage2 from "assets/images/testimages/testImage2.webp";
import TestImage3 from "assets/images/testimages/testImage3.webp";
import TestImage4 from "assets/images/testimages/testImage4.webp";
import TestImage5 from "assets/images/testimages/testImage5.webp";
import TestImage6 from "assets/images/testimages/testImage6.webp";
import TestImage7 from "assets/images/testimages/testImage7.webp";
import TestImage8 from "assets/images/testimages/testImage8.webp";
import Distance from "assets/images/distance.webp";
import Weight from "assets/images/weight.webp";
import CompassImage from "assets/images/freeAssets/compass.webp";
import DocumentsImage from "assets/images/freeAssets/documents.webp";
import NewLogo from "assets/images/logo/rossLogo.webp";
import PCB from "assets/images/products/PCB/PCB.webp";
import Chip from "assets/images/chip.webp";
import Robot from "assets/images/products/Robot/robot.webp";
// Gallery_mpak
import G_mpak_1 from "assets/images/products/Gallery_mpak/111.webp";
import G_mpak_2 from "assets/images/products/Gallery_mpak/555.webp";
import G_mpak_3 from "assets/images/products/Gallery_mpak/666.webp";
import G_mpak_4 from "assets/images/products/Gallery_mpak/777.webp";
import G_mpak_5 from "assets/images/products/Gallery_mpak/888.webp";
import G_mpak_6 from "assets/images/products/Gallery_mpak/999.webp";
import G_mpak_7 from "assets/images/products/Gallery_mpak/1010.webp";
import G_mpak_8 from "assets/images/products/Gallery_mpak/1012.webp";
import G_mpak_9 from "assets/images/products/Gallery_mpak/1100.webp";
import G_mpak_10 from "assets/images/products/Gallery_mpak/1200.webp";
// PGP
import PGP1 from "assets/images/products/PGP/pgp1.webp";
import PGP2 from "assets/images/products/PGP/pgp2.webp";
// Gallery_pgp06DN
import PGPfoto from "assets/images/products/Gallery_pgp06DN/1212.webp";
// Gallery_pgp06DN
import PGPndview from "assets/images/products/Gallery_pgp06ND/444.webp";
// Vertical
import CameraVertical from "assets/images/products/Vertical/cameraVertical.webp";
// MPU
import Volks1 from "assets/images/products/MPU/Volkswagen1.webp";
import Volks2 from "assets/images/products/MPU/Volkswagen2.webp";
import Volksnoback from "assets/images/products/MPU/Volkswagen1_noback.webp";
import G_mpu_1 from "assets/images/products/MPU/3333.webp";
import G_mpu_2 from "assets/images/products/MPU/4444.webp";
import G_mpu_3 from "assets/images/products/MPU/5555.webp";
import G_mpu_4 from "assets/images/products/MPU/6666.webp";
// Nablidatel
import Nabl from "assets/images/products//Nabludatel/nabl.webp";
import G_nabl_1 from "assets/images/products//Nabludatel/123123.webp";
import G_nabl_2 from "assets/images/products//Nabludatel/456456.webp";
import NablN from "assets/images/products//NabludatelN/nablN.webp";
import G_nablN_1 from "assets/images/products//NabludatelN/3434.webp";
import G_nablN_2 from "assets/images/products//NabludatelN/5656.webp";
// Vzglyad
import Vzglyad from "assets/images/products/Vzglyad/vzglyad.webp";
import G_vzglyad_1 from "assets/images/products/Vzglyad/0101.webp";
import VzglyadN from "assets/images/products/VzglyadN/vzglyadN.webp";
import G_vzglyadN_1 from "assets/images/products/VzglyadN/0202.webp";
// Snegir
import Snegir from "assets/images/products/Snegir/sticker.webp";
import G_snegir_1 from "assets/images/products/Snegir/snegir.webp";
import G_snegirN_1 from "assets/images/products/Snegir/snegirN.webp";

export const icons = {
  chevronDown1: chevronDownIcon1,
  hamburger: HamburgerIcon,
};

export const images = {
  Aero,
  Compass,
  Test,
  Snegir,
  TestImage1,
  TestImage2,
  TestImage3,
  TestImage4,
  TestImage5,
  TestImage6,
  TestImage7,
  TestImage8,
  Distance,
  Weight,
  CompassImage,
  DocumentsImage,
  NewLogo,
  PCB,
  Chip,
  Robot,
  G_mpak_1,
  G_mpak_2,
  G_mpak_3,
  G_mpak_4,
  G_mpak_5,
  G_mpak_6,
  G_mpak_7,
  G_mpak_8,
  G_mpak_9,
  G_mpak_10,
  PGP1,
  PGP2,
  PGPfoto,
  PGPndview,
  CameraVertical,
  Volks1,
  Volks2,
  G_mpu_1,
  G_mpu_2,
  G_mpu_3,
  G_mpu_4,
  Volksnoback,
  Nabl,
  G_nabl_1,
  G_nabl_2,
  NablN,
  G_nablN_1,
  G_nablN_2,
  Vzglyad,
  G_vzglyad_1,
  VzglyadN,
  G_vzglyadN_1,
  G_snegir_1,
  G_snegirN_1,
};