import { FC } from 'react';
import {styled, Typography, TypographyProps } from '@mui/material';

const StyledTitle = styled(Typography)(({ theme }) => ({
    fontWeight: '600',
    color: theme.palette.text.primary,
    fontSize: "36px",
    lineHeight: '26px',
    textWrap: 'wrap',
    textAlign: 'center',
    whiteSpace: "pre-line",
    // textWrap: 'wrap',
    wordBreak: 'break-word',
    // overflow: 'break-word',
    nowrap: false,
    [theme.breakpoints.up('xs')]: {
      marginBottom: '5px',
    },
    [theme.breakpoints.up('sm')]: {
      marginBottom: '18px',
    },
}));

interface Props {
    children?: React.ReactNode 
    fontSizeXs?: string,
    fontSizeMd?: string,
    fontWeight?: string,
    textAlign?: "center" | "end" | "justify" | "left" | "match-parent" | "right" | "start",
    padding?: string
}

const MainTitle: FC<Props> = ({ children, fontSizeXs, fontSizeMd, fontWeight, textAlign, padding, ...props }) => (
  <StyledTitle sx={{fontSize: {xs: fontSizeXs, md: fontSizeMd}, fontWeight, textAlign, padding}}  {...props}>
    {children}
  </StyledTitle>
);

export { MainTitle };
