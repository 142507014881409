import { PaletteOptions } from '@mui/material';

const COMMON_COLORS = {
  black: '#000000',
  white: '#ffffff',
  // custom
  primary: '#337AB7',
  secondary1: '#219ebc',
  secondary2: '#ffb703',
  secondary3: '#ffb703',
  dark1: 'rgb(64, 162, 227,0.1)',
  dark2: 'rgb(64, 162, 227,0.1)',
  dark3: '#ffb703',
  dark4: '#ffb703',
  gradient: 'linear-gradient(180deg, #CDC392 0%, #938852 20%)',
};

const PRIMARY_COLOR = {
  main: COMMON_COLORS.primary,
  light: COMMON_COLORS.primary,
  dark: COMMON_COLORS.primary,
};

const TEXT_COLOR = {
  primary: COMMON_COLORS.white,
  secondary: 'rgba(255, 255, 255, 0.7)',
  disasbled: 'rgba(255, 255, 255, 0.7)',
};

const BACKGROUND_COLOR = {
  default: COMMON_COLORS.dark1,
  paper: COMMON_COLORS.black,
  box: COMMON_COLORS.dark3,
  card: COMMON_COLORS.dark3,
  body: COMMON_COLORS.dark2,
  label: '#CDC392',
  buttonGold: COMMON_COLORS.gradient,
  buttonGray: COMMON_COLORS.dark4,
};

const paletteLight: PaletteOptions = {
  common: { ...COMMON_COLORS },
  primary: { ...PRIMARY_COLOR },
  text: { ...TEXT_COLOR },
  background: { ...BACKGROUND_COLOR },
};

const paletteDark: PaletteOptions = {
  common: { ...COMMON_COLORS },
  primary: { ...PRIMARY_COLOR },
  text: { ...TEXT_COLOR },
  background: { ...BACKGROUND_COLOR },
};

export default { paletteLight, paletteDark };
