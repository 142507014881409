import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const photoSections = [
   {
      original: images.G_mpak_8,
      thumbnail: images.G_mpak_8,
    },
    {
      original: images.G_mpak_1,
      thumbnail: images.G_mpak_1,
    },
    {
      original: images.G_mpu_3,
      thumbnail: images.G_mpu_3,
    },
    {
      original: images.G_mpak_10,
      thumbnail: images.G_mpak_10,
    },
    {
      original: images.G_mpak_2,
      thumbnail: images.G_mpak_2,
    },
    {
      original: images.G_mpu_1,
      thumbnail: images.G_mpu_1,
    },

    {
      original: images.PGPndview,
      thumbnail: images.PGPndview,
    },
]  

const photoPageSections = [...photoSections];

export {photoPageSections}