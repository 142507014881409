import { useEffect, useState } from "react";
import { Box, Grid, styled } from "@mui/material";
import Navigation from "components/Navigation/Navigation";
import GoalCard from "components/AboutSection/GoalCard";
import { goalsPageSections } from "config/constants/goals";

const OverviewSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: "transparent",
  //   alignItems: 'center',
  height: "100%",
  minHeight: "600px",
  gap: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  //   justifyContent: 'center',
  marginBottom: "50px",
  maxWidth: "1200px",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
    paddingRight: "20px",
    paddingLeft: "20px",
  },
}));

const PossibilitysPage = () => {
  const [navigationUrl, setNavigationUrl] = useState<any>([]);
  const goals = goalsPageSections;
  useEffect(() => {
    const a = document.createElement("a");
    const url = new URL(window.location.href);
    const res = url.pathname.split("/");
    setNavigationUrl(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <OverviewSection mt={0} key="About Page">
      <Navigation data={navigationUrl} />
      <Box
        mt={2}
        mb={2}
        justifyContent="center"
        sx={{ background: "transparent", display: "flex", width: "100%" }}
      >
        <Grid
          container
          spacing={2}
          justifyContent="center"
          sx={{
            width: "100%",
            background: "transparent",
            border: "0px solid blue",
          }}
        >
          {goals.map((value: any, index: number) => (
            <Grid item key={value.title} md={3} lg={2} xs={12}>
              <GoalCard data={value} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </OverviewSection>
  );
};

export default PossibilitysPage;
