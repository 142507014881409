import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";

import { CircularProgress } from '@mui/material';
import NotificationProvider from 'context/NotificationContext';

import Router from './routes';
import { store } from './state/store';
import { ThemeConfig } from './theme';

function App(): JSX.Element {
  return (
      <BrowserRouter>
        <Provider store={store}>
          <ThemeConfig>
            <NotificationProvider>
              <Suspense fallback={<CircularProgress sx={{ margin: '50px auto' }} />}>
                <Router />
              </Suspense>
            </NotificationProvider>
          </ThemeConfig>
        </Provider>
      </BrowserRouter>
  );
}

export default App;
