import { FC, Fragment, useEffect, useState } from 'react';
import { Box, Grid, styled, Typography } from '@mui/material';

import { useAppSelector } from 'state/hooks';
import { Link } from 'react-scroll';
import { MainTitle } from 'components/common/Title';
import { aboutPageSections } from 'config/constants/about';
import { ObjectCreator } from 'components/common/Creator/ObjectCreator';
import Navigation from 'components/Navigation/Navigation';
import { useNavigate } from 'react-router';
import { photoPageSections } from 'config/constants/photos';
import PhotoCard from 'components/PhotoSection/PhotoCard';
import Lightbox from "react-image-lightbox";

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  background: 'transparent',
//   alignItems: 'center',
  height: '100%',
  minHeight: '600px',
  gap: '20px',
  paddingRight: '20px',
  paddingLeft: '20px',
//   justifyContent: 'center',
  marginBottom: '50px',
  maxWidth: '1200px',

  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

// overview section
const MainSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  // gap: '20px',
  background: 'rgba(25,54,82,0.77)',
  boxShadow:
  '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
 // 5A687D
  alignItems: 'center',
  minHeight: '500px',
  padding: '5px',
  "&:hover": {
    background: 'rgba(25,54,82,1.0)',
    opacity: 1.0,
    // boxShadow:
    // '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
   // 5A687D
  },
  transition: 'all 0.75s ease-in-out',
  borderRadius: '10px',
  overflow: 'clip',
  justifyContent: 'center',
  position: 'relative',
  [theme.breakpoints.up('xs')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'column',
  },
}));

const OverviewTitle = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  color: theme.palette.text.primary,
  fontSize: '47px',
  lineHeight: '26px',

  [theme.breakpoints.up('xs')]: {
    marginBottom: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: '18px',
  },
}));
const OverviewSubTitle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  color: theme.palette.text.primary,
  fontSize: '20px',
  lineHeight: '26px',
  maxWidth: '500px',
  lineWidth: '500px',
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: 'break-word',
  // overflow: 'break-word',
  nowrap: false,
  // textOverflow: "ellipsis",
  // overflowWrap: 'break-word',
  // wordBreak: "break-word",
  [theme.breakpoints.up('xs')]: {
    marginBottom: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    marginBottom: '18px',
  },
}));

const RootStyle = styled("section")({
  height: '100%',
  position: 'relative'
});

const LinkStyle = styled(Link)({
  lineHeight: 1.2,
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: 500,
  color: "white",
  borderTop: '1px solid white',
  borderBottom  : '1px solid white',
  borderRight: '1px solid white',
  borderLeft: '1px solid white',
  minWidth: '120px',
  padding: "10px",
  textAlign: 'center',
  "&:hover": {
    color: "white",
    backgroundColor: 'gray'
  },
  "&.active": {
    color: "white",
    backgroundColor: 'gray'
  },
});

const ToggleIcon = styled('img')(() => ({}));
const sections = aboutPageSections;

const GalleryPage = () => {
  const [navigationUrl, setNavigationUrl] = useState<any>([]);
  useEffect(() => {
    const a = document.createElement('a');
    const url = new URL(window.location.href);
    const res = url.pathname.split('/');
    setNavigationUrl(res)
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [window.location.href]);

const [photoIndex, setIndex] = useState(-1);
const navigate = useNavigate();
const photos = photoPageSections
const currentImage: any = photoIndex >= 0 ? photos[photoIndex] : {};
const nextIndex = (photoIndex + 1) % photos.length;
const nextImage = photos[nextIndex] || currentImage;
const prevIndex = (photoIndex + photos.length - 1) % photos.length;
const prevImage = photos[prevIndex] || currentImage;
const onImgClick = async (e: any) => {
    // setIsMouseOnTarget(false)
    setIndex(e)
  };
const handleClick = (indexSelected: number) => setIndex(indexSelected);
const handleClose = () => setIndex(-1);
const handleMovePrev = () => setIndex(prevIndex);
const handleMoveNext = () => setIndex(nextIndex);

  return (
    <>
          <OverviewSection mt={0} key="About Page">
            {/* <img alt='' src={images.Aero} style={{position: 'absolute', right: 0, bottom: 0}}/> */}

            <MainSection key="Company Section">
            <Navigation data={navigationUrl}/>
            <Grid container spacing={1} sx={{height: '100%', padding: '20px', marginBottom: '30px'}}>
            <>{photos && photos
              .map((value: any, index: number) =>
                <>
                {index >= 0 &&
                  <Grid  item xs={12} md={4} sx={{border: '0px solid red', height: '100%', width: '100%', padding: '0px'}}>
                    <Box display='flex' onClick={() => {onImgClick(index)}}>
                      <PhotoCard data={value}/>
                    </Box>
                  </Grid>
                } 
                </> 
            )}</> 

          </Grid>
{/*  */}
            </MainSection>
          </OverviewSection>
          {photoIndex >= 0 && (
                    /* @ts-ignore */
                    <Lightbox
                    mainSrc={currentImage?.original}
                    imageTitle=""
                    mainSrcThumbnail={currentImage?.thumbnail}
                    nextSrc={nextImage.original}
                    nextSrcThumbnail={nextImage.thumbnail}
                    prevSrc={prevImage.original}
                    prevSrcThumbnail={prevImage.thumbnail}
                    onCloseRequest={handleClose}
                    onMovePrevRequest={handleMovePrev}
                    onMoveNextRequest={handleMoveNext}
                    />
        )}
        
    </>
  );
};

export default GalleryPage;
