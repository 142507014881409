import { icons } from 'config/constants/assets';

export interface RouteType {
  key: string;
  text: string;
  url: string;
}

export const routes: { [key: number]: RouteType[] } = {
  1: [
    { key: 'Stake', text: 'Stake', url: '' },
  ],
};

export const extraMenuItems: { [key: number]: any[] } = {
  1: [
    {
      name: 'МЕНЮ',
      icon: "",
      key: 'main menu',
      hasSubItems: true,
      subItems: [
        { name: 'ГЛАВНАЯ', key: 'home', link: '/', color: '#CCC292', enabled: true },
        { name: 'ВОЗМОЖНОСТИ', key: 'possibilitys', link: '/possibilitys', color: '#CCC292', enabled: true },
        { name: 'ПРОДУКЦИЯ', key: 'products', link: '/products', color: '#CCC292', enabled: true },
        { name: 'О КОМПАНИИ', key: 'products', link: '/company', color: '#CCC292', enabled: true },
        { name: 'ФОТОГРАФИИ', key: 'gallery', link: '/gallery', color: '#CCC292', enabled: true },
        { name: 'КОНТАКТЫ', key: 'contacts', link: '/company/contacts', color: '#CCC292', enabled: true },
        { name: 'ЛИЦЕНЗИИ И СЕРТИФИКАТЫ', key: 'licenses', link: '/company/licenses', color: '#CCC292', enabled: true },
      ],
    },
  ],
};
