import { FC, Fragment, useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";

import { licensesPageSections } from "config/constants/licenses";
import { ObjectCreator } from "components/common/Creator/ObjectCreator";
import Navigation from "components/Navigation/Navigation";

// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: "transparent",
  alignItems: "center",
  height: "100%",
  minHeight: "600px",
  gap: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  justifyContent: "center",
  marginBottom: "50px",
  maxWidth: "1200px",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
  },
}));

// overview section
const MainSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  // gap: '20px',
  background: "rgba(25,54,82,0.77)",
  boxShadow:
    "0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)",
  // 5A687D
  alignItems: "center",
  minHeight: "500px",
  padding: "5px",
  "&:hover": {
    background: "rgba(25,54,82,1.0)",
    opacity: 1.0,
    // boxShadow:
    // '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    // 5A687D
  },
  transition: "all 0.75s ease-in-out",
  borderRadius: "10px",
  overflow: "clip",
  justifyContent: "center",
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
  },
}));

const sections = licensesPageSections;

const LicensesPage = () => {
  const [navigationUrl, setNavigationUrl] = useState<any>([]);
  useEffect(() => {
    const a = document.createElement("a");
    const url = new URL(window.location.href);
    const res = url.pathname.split("/");
    setNavigationUrl(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  return (
    <OverviewSection mt={0} key="About Page">
      <MainSection key="Company Section">
        <Navigation data={navigationUrl} />
        <Box mt={5}>
          <>
            {sections &&
              sections.map((row: any) => (
                <ObjectCreator key={row.key} data={row} />
              ))}
          </>
        </Box>
      </MainSection>
    </OverviewSection>
  );
};

export default LicensesPage;
