import { FC } from 'react';
import {Box, styled, Typography } from '@mui/material';
import { MainTitle } from 'components/common/Title';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const Text = styled(Typography)(({ theme }) => ({
    fontStyle: 'normal',
    fontWeight: '600',
    // fontFamily: 'Open Sans',
    color: theme.palette.text.primary,
    fontSize: '20px',
    lineHeight: '26px',
    maxWidth: '100%',
    lineWidth: '500px',
    textWrap: 'wrap',
    textAlign: 'left',
    whiteSpace: "pre-line",
    // textWrap: 'wrap',
    wordBreak: 'break-word',
    // overflow: 'break-word',
    nowrap: false,
    // textOverflow: "ellipsis",
    // overflowWrap: 'break-word',
    // wordBreak: "break-word",
    [theme.breakpoints.up('xs')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
    },
  }));

interface Props {
    children?: React.ReactNode,
    data: any
}

const TextListBox: FC<Props> = ({ children, data, ...props }) => {
    return (
        <Box mt={4} gap='5px' display='flex' flexDirection='column' sx={{padding: '10px', width: '100%'}}>
            <MainTitle textAlign='left' padding='0' fontSizeXs='20px'>{data?.title}</MainTitle>
            <Box display='flex' gap='5px' sx={{maxWidth: '100%', borderRadius: '10px', flexDirection: 'column'}}>
                <>{data?.text && data?.text
                .map((row: any) =>  
                    <Box display='flex' gap='5px' >
                         <RadioButtonUncheckedIcon/>
                         <Text>{row}</Text>
                    </Box>
                )}</> 
            </Box>
        </Box>
    );
}

export { TextListBox };
