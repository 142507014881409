import { ComponentsCategory, ContactsFieldType } from "types/components";
import { images } from "./assets";

const contactsSections = [
  {
    type: ComponentsCategory.CONTACTS_BOX,
    footerInfo: {
      phoneNumberVisible: "+7 (495) 675-60-29",
      phoneNumberLink:"tel:+74956756029",
      emailVisible: "ross@ross-jsc.ru",
      emailLink: "mailto:ross@ross-jsc.ru",
    },
    data: {
      img: images.CompassImage,
      fields: [
        {
          title: "Телефон:",
          value: [
            {
              visibleValue: "+7(495)675-60-29",
              linkValue: "tel:+74956756029",
            },
            {
              visibleValue: "+7(222)123-45-67",
              linkValue: "tel:+72221234567",
            },
          ],
          type: ContactsFieldType.TITLE_AND_LINK,
          offset: 0,
        },
        {
          title: "Факс: ",
          value: [
            {
              visibleValue: "+7(495)675-60-29",
              linkValue: "",
            },
          ],
          type: ContactsFieldType.TITLE_AND_VALUE,
          offset: 2,
        },
        {
          title: "Email: ",
          value: [
            {
              visibleValue: "ross@ross-jsc.ru",
              linkValue: "mailto:ross@ross-jsc.ru",
            },
          ],
          type: ContactsFieldType.TITLE_AND_LINK,
          offset: 2,
        },
        {
          title: "Почтовый адрес:    ",
          value: [
            {
              visibleValue: "123456,г.Москва,а/я 1234",
              linkValue: "",
            },
          ],
          type: ContactsFieldType.TITLE_AND_VALUE,
          offset: 2,
        },
        {
          title:
            "По вопросам связанным с финансовой деятельностью Вам ответит финансовый директор Дробаха Инга Сергеевна",
          value: [
            {
              visibleValue: "",
              linkValue: "",
            },
          ],
          type: ContactsFieldType.TITLE,
          offset: 4,
        },
        {
          title: "",
          value: [
            {
              visibleValue: "i.drobaha@ross-jsc.ru",
              linkValue: "mailto:i.drobaha@ross-jsc.ru",
            },
          ],
          type: ContactsFieldType.LINK,
          offset: 0,
        },
        {
          title:
            "По общим вопросам обращайтесь к заместителю директора Обрезановой Ирине Вадимовне",
          value: [
            {
              visibleValue: "",
              linkValue: "",
            },
          ],
          type: ContactsFieldType.TITLE,
          offset: 2,
        },
        {
          title: "",
          value: [
            {
              visibleValue: "i.obrezanova@ross-jsc.ru",
              linkValue: "mailto:i.obrezanova@ross-jsc.ru",
            },
          ],
          type: ContactsFieldType.LINK,
          offset: 0,
        },
      ],
    },
  },
];

const contactsPageSections = [...contactsSections];

export { contactsPageSections };
