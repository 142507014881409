import { FC } from "react";
import { styled, Typography, TypographyProps } from "@mui/material";
import { ComponentsCategory } from "types/components";
import { ImageTextBox } from "components/ImageTextBox/ImageTextBox";
import { TextListBox } from "components/TextListBox/TextListBox";
import { InfoBox } from "components/InfoBox/InfoBox";
import { ContactsList } from "components/ContactsList/ContactsList";
import { WhideImage } from "components/WhideImage/WhideImage";
import { ImageButton } from "components/ImageButton/ImageButton";

import { MainTitle } from "../Title";

interface Props {
  children?: React.ReactNode;
  data: any;
}

const ObjectCreator: FC<Props> = ({ children, data, ...props }) => {
  const renderItem = (input: any) => (
    <>
      {input?.type === ComponentsCategory.MAIN_TITLE && (
        <MainTitle
          fontSizeXs={input?.data?.fontSizeXs}
          fontSizeMd={input?.data?.fontSizeMd}
          padding={input?.data?.padding}
        >
          {input?.data?.value}
          {input?.childrens && (
            <>
              <>
                {input?.childrens &&
                  input?.childrens.map((row: any) => (
                    <ObjectCreator data={row} />
                  ))}
              </>
            </>
          )}
        </MainTitle>
      )}
      {input?.type === ComponentsCategory.IMAGE_TEXT_BOX && (
        <>
          <ImageTextBox data={input?.data} />
        </>
      )}
      {input?.type === ComponentsCategory.TEXT_LIST_BOX && (
        <>
          <TextListBox data={input?.data} />
        </>
      )}
      {input?.type === ComponentsCategory.INFO_BOX && (
        <>
          <InfoBox data={input?.data} />
        </>
      )}
      {input?.type === ComponentsCategory.CONTACTS_BOX && (
        <>
          <ContactsList data={input?.data} />
        </>
      )}
      {input?.type === ComponentsCategory.WHIDE_IMAGE && (
        <>
          <WhideImage data={input?.data} />
        </>
      )}
      {input?.type === ComponentsCategory.IMAGE_BUTTON && (
        <>
          <ImageButton data={input?.data} />
        </>
      )}
    </>
  );

  return (
    <div style={{ width: "100%", paddingLeft: "20px", paddingRight: "20px" }}>
      {renderItem(data)}
      {children}
    </div>
  );
};

export { ObjectCreator };
