import { FC } from 'react';
import { Box, Slide, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useWeb3React } from '@web3-react/core';
import { ReactSVG } from 'react-svg';
import { useLocation, useNavigate } from 'react-router-dom';

import { icons, images } from 'config/constants/assets';
import { getBalanceInEther } from 'utils/formatBalance';
import { extraMenuItems } from 'config/constants/header';
import { useAppSelector } from 'state/hooks';
import { Link} from 'react-scroll';
import CloseIcon from '@mui/icons-material/Close';

const ExtraMenuContainer = styled(Box)(({ theme }) => ({}));

const ExtraMenuContent = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  zIndex: 150,
  background: '#254c6e',
  padding: '35px 35px',
  height: '100vh',
  width: '400px',
  transition: '2.5s all ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',

  [theme.breakpoints.up('xs')]: {},
  [theme.breakpoints.up('sm')]: {},
}));

const OverlayBg = styled(Box)(() => ({
  position: 'fixed',
  top: '0px',
  left: '0px',
  right: '0px',
  bottom: '0px',
  zIndex: 10,
  background: '#000000',
  opacity: 0.3,
}));

// project section
const ProjectSection = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
}));

const ProjectLogo = styled('img')(() => ({
  width: '100%'
}));
const ProjectName = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '400',
  cursor: 'pointer',
  fontSize: '19px',
  lineHeight: 'normal',
  color: '#ffffff',
}));
const CollapseButton = styled(Box)(() => ({
  position: 'absolute',
  right: '-50px',
  height: '40px',
  width: '40px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  background: '#2A3139',
  cursor: 'pointer',
  boxShadow:
    '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',

  svg: {
    transform: 'rotate(90deg)',

    path: {
      stroke: 'white',
    },
  },
}));

// account section
const AccountSection = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  background: 'rgba(217, 217, 217, 0.20)',
  padding: '17px 19px',
}));

const AccountLogo = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginRight: '15px',
}));

const AccountImg = styled('img')(({theme}) => ({
  borderRadius: '50%',

  [theme.breakpoints.up('xs')]: {
    height: '37px',
    width: '37px',
  },
  [theme.breakpoints.up('sm')]: {
    height: '50px',
    width: '50px',
  },
}));

const AccountBalance = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const AccountBalanceLabel = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '11px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 0.7)',
}));

const AccountBalanceValue = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  cursor: 'pointer',
  fontSize: '16px',
  lineHeight: 'normal',
  color: '#FFFFFF',

  'span:first-of-type': {
    fontSize: '16px',
    marginRight: '6px',
  },
  'span:last-of-type': {
    fontSize: '10px',
  },
}));

const AccountAssets = styled(Box)(() => ({
  position: 'absolute',
  right: '16px',
}));

const AccountAssetImages = styled('img')(() => ({}));

// menu section
const MenuSection = styled(Box)(({ theme }) => ({}));

const MenuTitle = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '11px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 0.60)',
}));

const MenuContent = styled(Box)(() => ({
  marginTop: '10px',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

// menu item
const MenuItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  justifyContent: 'flex-start',
  // '&:hover': {
  //   opacity: '0.8',
  // },
}));
const MenuItemIcon = styled('img')(() => ({
  marginRight: '12px',
}));
const MenuItemName = styled(Typography)(() => ({
  // fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '600',
  // cursor: 'pointer',
  fontSize: '18px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 1.0)',
  // "&:hover": {
  //   color: "#fff",
  //   borderBottom: '1px solid white'
  // },
}));

// menu sub item
const MenuSubItemContent = styled(Box)(() => ({
  // marginLeft: '28px',
  // width: '100%',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
}));

const MenuSubItem = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',

  // '&:hover': {
  //   opacity: '0.8',
  // },
}));
const MenuSubItemIcon = styled(Box)(() => ({
  width: '5px',
  height: '5px',
  borderRadius: '50%',
  marginRight: '12px',
}));

const MenuSubItemName = styled(Typography)(() => ({
  fontFamily: 'Prompt',
  fontStyle: 'normal',
  fontWeight: '300',
  cursor: 'pointer',
  fontSize: '14px',
  lineHeight: 'normal',
  color: 'rgba(255, 255, 255, 0.90)',
}));

const LinkStyle = styled(Link)(({theme}) => ({
  lineHeight: 0.8,
  cursor: "pointer",
  fontSize: "25px",
  fontWeight: 500,
  color: theme.palette.common.gradient,
  borderTop: '0px solid white',
  borderBottom  : '0px solid white',
  borderRight: '0px solid white',
  borderLeft: '0px solid white',
  minWidth: '120px',
  maxHeight: '35px',
  padding: "8px",
  textAlign: 'left',
  // background: theme.palette.common.gradient,
  "&:hover": {
    color: "white",
    borderColor: '#5F5D9C'
  },
  "&.active": {
    color: "white",
    borderColor: '#5F5D9C'
  },
}));

const Label = styled(Typography)(({theme}) => ({
  // fontFamily: 'Open Sans',
  fontStyle: 'normal',
  fontWeight: '700',
  cursor: 'pointer',
  // fontSize: '25px',
  lineHeight: '40px',
  color: 'rgba(255, 255, 255, 0.7)',
  "&:hover": {
    color: "#fff",
    borderBottom: '1px solid white'
  },
  transition: 'all 0.25s ease-in-out',
  [theme.breakpoints.up('xs')]: {
    fontSize: '20px',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '25px',
  },
}));

interface ExtraMenuProps {
  onClose: () => void;
}

const ExtraMenu: FC<ExtraMenuProps> = ({ onClose }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { selectedChainId } = useAppSelector((state) => state.chain);

  // close opended extra menu
  const onCollapse = () => {
    onClose();
  };

  const onClickLogo = () => {};

  const onClickMenu = (menu: any) => {
    if (menu.link) {
      // window.open(menu.link, '_blank');
    }
  };

  const onClickSubMenu = (menu: any) => {
    if (menu.isExternal) {
      window.open(menu.link, '_blank');
    } else {
      window.scrollTo({left: 0, top: 0})
      navigate(menu.link);
      onCollapse();
    }
  };
  const pageName = pathname.replace('/', '').split('/')[0];
  
  return (
    <ExtraMenuContainer>
      <OverlayBg onClick={onCollapse} />

      {/* {isMobile && 
            <OverlayBg onClick={onCollapse} />
      } */}
      <ExtraMenuContent>
        <MenuSection>
          {/* <MenuTitle>Main Menu</MenuTitle> */}
          <MenuContent>
            {extraMenuItems[1] &&
              extraMenuItems[1].map((row) => (
                <Box key={row.key}>
                  <MenuItem textAlign='left' mb={2} onClick={onCollapse} sx={{ opacity: row.link ? '1' : '1', border: '0px solid red' }}>
                    <CloseIcon/>
                    <MenuItemName ml={2}>{row.name}</MenuItemName>
                  </MenuItem>
                  {row.hasSubItems && row.subItems && row.subItems.length > 0 && (
                    <MenuSubItemContent>
                      {row.subItems &&
                        row.subItems.map((subItem: any) => (
                          <LinkStyle
                          onClick={() => onClickSubMenu(subItem)}
                          key={subItem.key}
                          to={subItem.url}
                          // sx={{opacity:  subItem.enabled ? '1.0' : '0.5', borderColor: pageName === subItem.key ? '#56558F' : '', pointerEvents: subItem.enabled ? '' : 'none'}}
                          // spy={!!true}
                          spyThrottle={10}
                          offset={-100}
                          smooth={!!true}
                          duration={500}
                            // onClick={() => {scroll()}}
                          // href='#tokenomics3'
                          // sx={{
                          //   borderLeft: "test" === 'home' ? '1px solid white' : '0px solid white',
                          // }}
                          activeClass="active"
                        >
                          {/* <Typography variant='body1'> */}
                          <Label>
                            {subItem.name}
                          </Label>
                          {/* </Typography> */}
                      </LinkStyle>
                        ))}
                    </MenuSubItemContent>
                  )}
                </Box>
              ))}
          </MenuContent>
        </MenuSection>
      </ExtraMenuContent>
    </ExtraMenuContainer>
  );
};

export { ExtraMenu };
