import { ComponentsCategory } from "types/components";
import { images } from "./assets";

const licensesSections = [
  {
    type: ComponentsCategory.WHIDE_IMAGE,
    data: {
      img: images.DocumentsImage,
    },
  },
  {
    type: ComponentsCategory.TEXT_LIST_BOX,
    data: {
      // title: "ЛИЦЕНЗИИ И СЕРТИФИКАТЫ",
      text: ["Сертификат соответствия на изоляторы опорные полимерные наружной установки на номинальное напряжение 20, 35, 110 и 220 кВ ", 
          "Сертификат системы менеджмента качества соответствующий требованиям международного стандарта ISO 9001:2008 (2011-2014) ",
          "Лицензия на разработку, производство, испытания, установку, монтаж, техническое обслуживание, ремонт, утилизацию и реализацию вооружения и военной техники.",
          "Сертификат соответствия на ограничители перенапряжений нелинейные для сетей классов напряжения от 3 до 750 кВ, выпускаемых по ТУ 3414-035-06968694-2009 . "
      ]
    }
},
];

const licensesPageSections = [...licensesSections];

export { licensesPageSections };
