import React, { FC, useState } from "react";
import { Box, Modal, TextField } from "@mui/material";
import { productsPageSections } from "config/constants/products";
import { goalsPageSections } from "config/constants/goals";
import Searcher from "./Searcher";

const style = {
  position: "absolute" as const,
  top: "70px",
  left: "50%",
  transform: "translate(-50%)",
  width: { xs: "95%", md: "800px" },
  borderRadius: "5px",
  boxShadow: 24,
  p: 4,
  background: "linear-gradient(135deg, #265075 0%, #375e85 50%, #1d3d59 100%)",
  overflowY: "auto", // For vertical scroll
  maxHeight: "80%", // For vertical scroll
  outline: "none", // Remove outline when modal in focus
  // '&:focus-visible': {
  //   outline: '2px solid red',
  // },
  // '& *::selection': {
  //   backgroundColor: 'yellow',
  //   color: 'black',
  // },
};

interface SearchModalProps {
  onClose: () => void;
  open: boolean;
}

const SearchModal: FC<SearchModalProps> = ({ onClose, open }) => {
  // const [open, setOpen] = useState(true);

  const onCollapse = () => {
    onClose();
    // setOpen(false);
  };

  const handleClose = () => onCollapse();

  const products = productsPageSections;
  const possibilitys = goalsPageSections;

  const [query, setQuery] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(event.target.value);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box display="flex" flexDirection="column" gap="0px" sx={style}>
        <TextField
          // fullWidth
          hiddenLabel
          variant="filled"
          placeholder="ЧТО ИЩЕМ?"
          value={query}
          onChange={handleChange}
          sx={{
            input: { color: "#e8e8e8" },
            "& .MuiInputBase-input::placeholder": {
              color: "white",
              opacity: 0.8, // Forcing full opacity
            },
          }}
        />
        <Box onClick={() => handleClose()}>
          <Searcher
            productsData={products}
            possibilitysData={possibilitys}
            query={query}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default SearchModal;
