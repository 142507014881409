import { FC } from "react";
import { Box } from "@mui/material";

interface Props {
  children?: React.ReactNode;
  data: any;
}

const WhideImage: FC<Props> = ({ children, data, ...props }) => {
  return (
    <Box
      sx={{
        height: "90px",
        overflow: "clip",
        borderRadius: "10px",
        border: "1px solid grey",
      }}
    >
      <img
        style={{ width: "100%", height: "100%", objectFit: "cover" }}
        src={data?.img}
        alt=""
      />
    </Box>
  );
};

export { WhideImage };
