interface DataItem {
  [key: string]: any;
}

// Function for recursive search of a string in an object
function searchInObject(obj: any, searchString: string): boolean {
  if (typeof obj === "string") {
    return obj.toLowerCase().includes(searchString);
  }

  if (typeof obj === "object" && obj !== null) {
    return Object.values(obj).some((value) =>
      searchInObject(value, searchString)
    );
  }

  return false;
}

// Universal function for searching in an array of objects
export function searchInArray(
  array: DataItem[],
  searchString: string
): DataItem[] {
  if (!searchString) return array;

  const lowerSearchString = searchString.toLowerCase();

  return array.filter((item) => searchInObject(item, lowerSearchString));
}

export const searchInProductsItem = (
  item: any,
  searchQuery: string
): boolean => {
  const lowerQuery = searchQuery.toLowerCase();

  // Search in top level
  if (
    item.title?.toLowerCase().includes(lowerQuery) ||
    item.shortDescriprion?.toLowerCase().includes(lowerQuery)
  ) {
    return true;
  }

  // Search in keyNotes
  if (
    item.keyNotes?.some((note: any) =>
      note.title?.toLowerCase().includes(lowerQuery)
    )
  ) {
    return true;
  }

  // Search in fullDescription
  if (
    item.fullDescription?.some(
      (desc: any) =>
        desc.title?.toLowerCase().includes(lowerQuery) ||
        desc.values?.some((value: any) =>
          value.toLowerCase().includes(lowerQuery)
        )
    )
  ) {
    return true;
  }

  // Search in characteristics
  if (
    item.characteristics?.some(
      (char: any) =>
        char.title?.toLowerCase().includes(lowerQuery) ||
        char.value?.toLowerCase().includes(lowerQuery)
    )
  ) {
    return true;
  }

  return false;
};

export const searchInPossibilitysItem = (
  // example
  item: any,
  searchQuery: string
): boolean => {
  const lowerQuery = searchQuery.toLowerCase();

  // Search in top level
  if (item.title?.toLowerCase().includes(lowerQuery)) {
    return true;
  }

  // Search in MAIN_TITLE
  if (
    item.sections?.some((note: any) =>
      note.data?.value?.toLowerCase().includes(lowerQuery)
    )
  ) {
    return true;
  }

  // Search in IMAGE_TEXT_BOX
  if (
    item.sections?.some((note: any) =>
      note.data?.title?.toLowerCase().includes(lowerQuery)
    )
  ) {
    return true;
  }

  return false;
};
