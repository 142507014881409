import { icons } from 'config/constants/assets';

export interface SocialType {
  key: string;
  url: string;
  icon: any;
}

export const socials: SocialType[] = [
  // { key: 'telegram', url: '', icon: ""},
  // { key: 'twitter', url: '', icon: "" },
];
