import { FC, useEffect, useState } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import { Link } from "react-scroll";

import { useAppDispatch, useAppSelector } from "state/hooks";
import { routes, RouteType } from "config/constants/header";
import { icons, images } from "config/constants/assets";
import { AccountButton, ConnectWallet } from "components/common/Button";
import { ChainInfo } from "types/chain";
import { ExtraMenu } from "components/ExtraMenu";
import SearchIcon from "@mui/icons-material/Search";
import SearchModal from "components/SearchModal/SearchModal";

const AppHeader = styled(Box)(({ theme }) => ({
  height: "75px",
  position: "sticky",
  top: "0px",
  zIndex: 150,
  // background: window.scrollY > 0 ? '#16191E' : "transparent",
  // background: '#122638', // 0E1C28
  background: "#1a3752", // 0E1C28
  padding: "0px 24px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  // borderBottom: '1px solid rgba(255, 255, 255, 0.7)',

  [theme.breakpoints.up("xs")]: {
    height: "53px",
  },
  [theme.breakpoints.up("sm")]: {
    height: "75px",
  },
}));

const HeaderContent = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "16px",
  width: "100%",
  maxWidth: "1150px",
  margin: "auto",
}));

// logo section
const LogoSection = styled(Box)(() => ({
  cursor: "pointer",
  "&:hover .hover-highlight": {
    color: "#fff",
    borderBottom: "1px solid white",
  },
}));

const LogoImg = styled("img")(() => ({
  minWidth: "40px",
  maxWidth: "40px",
}));
const MenuImg = styled("img")(() => ({
  minWidth: "22px",
  maxWidth: "25px",
}));

// menu section
const MenuSection = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "20px",
  position: "relative",
  textAlign: "center",
  alignItems: "center",

  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
  [theme.breakpoints.up("md")]: {
    display: "flex",
    gap: "30px",
  },
  [theme.breakpoints.up("lg")]: {
    display: "flex",
    gap: "30px",
  },
}));

const MenuItem = styled(Typography)(() => ({
  fontFamily: "Prompt",
  fontStyle: "normal",
  fontWeight: "400",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "24px",
}));

// chain section
const ChainSection = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up("xs")]: {
    display: "block",
  },
  [theme.breakpoints.up("md")]: {
    display: "block",
  },
}));

// action section
const AccountSection = styled(Box)(() => ({
  display: "flex",
  position: "relative",
  textAlign: "center",
  alignItems: "center",
  "&:hover .hover-highlight": {
    color: "#fff",
    borderBottom: "1px solid white",
  },
}));

const AccountBalance = styled(Box)(({ theme }) => ({
  marginRight: "12px",

  [theme.breakpoints.up("xs")]: {
    display: "none",
  },
  [theme.breakpoints.up("sm")]: {
    display: "block",
    marginRight: "12px",
  },
  [theme.breakpoints.up("lg")]: {
    display: "block",
    marginRight: "24px",
  },
}));

const AccountBalanceLabel = styled(Typography)(() => ({
  // fontFamily: 'Open Sans',
  fontStyle: "normal",
  fontWeight: "700",
  cursor: "pointer",
  fontSize: "20px",
  lineHeight: "18px",
  // "&:hover": {
  //   color: "#fff",
  //   borderBottom: '1px solid white'
  // },
  color: "rgba(255, 255, 255, 1.0)",
}));

const Label = styled(Typography)(() => ({
  // fontFamily: 'Open Sans',
  fontStyle: "normal",
  fontWeight: "700",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "18px",
  color: "rgba(255, 255, 255, 0.7)",
  // "&:hover": {
  //   color: "#fff",
  //   borderBottom: '1px solid white'
  // },
  transition: "all 0.25s ease-in-out",
}));

const AccountBalanceValue = styled(Typography)(() => ({
  fontFamily: "Prompt",
  fontStyle: "normal",
  fontWeight: "600",
  cursor: "pointer",
  fontSize: "16px",
  lineHeight: "24px",
  color: "#FFFFFF",
}));

const LinkStyle = styled(Link)(({ theme }) => ({
  lineHeight: 0.8,
  cursor: "pointer",
  fontSize: "1rem",
  fontWeight: 500,
  color: theme.palette.common.gradient,
  borderTop: "1px solid white",
  borderBottom: "1px solid white",
  borderRight: "1px solid white",
  borderLeft: "1px solid white",
  minWidth: "120px",
  maxHeight: "35px",
  padding: "8px",
  textAlign: "center",
  // background: theme.palette.common.gradient,
  "&:hover": {
    color: "white",
    borderColor: "#5F5D9C",
  },
  "&.active": {
    color: "white",
    borderColor: "#5F5D9C",
  },
}));

const Header: FC = () => {
  const [tempChainSelectOption, setTempChainSelectOption] = useState<any>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [extraMenuOpened, setExtraMenuOpened] = useState<boolean>(false);
  const [searchOpened, setSearchOpened] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { selectedChainId } = useAppSelector((state) => state.chain);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const onCloseExtraMenu = () => {
    setExtraMenuOpened(false);
  };
  const [searchIsOpen, setOpen] = useState(false);
  const onCloseSearch = () => {
    setSearchOpened(false);
    setOpen(false);
  };

  const getHeaderActiveColor = () => {
    if (Number(selectedChainId) === 250) return "#716C54";
    if (Number(selectedChainId) === 137) return "#716C54";
    if (Number(selectedChainId) === 43114) return "#716C54";
    return "#716C54";
  };

  const scroll = () => {
    const section = document.querySelector("#tokenomics3");
    console.log(section);
    if (section) {
      console.log("scroll");
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <AppHeader>
      {extraMenuOpened && <ExtraMenu onClose={onCloseExtraMenu} />}
      <SearchModal open={searchIsOpen} onClose={onCloseSearch} />
      <HeaderContent>
        <LogoSection display="flex" flexDirection="row" alignItems="center">
          <MenuImg
            sx={{ mr: 2 }}
            alt="logo"
            onClick={() => setExtraMenuOpened(true)}
            src={icons.hamburger}
          />
          {!isMobile && (
            <Label
              className="hover-highlight"
              onClick={() => setExtraMenuOpened(true)}
            >
              МЕНЮ
            </Label>
          )}
        </LogoSection>

        {/* <LogoSection
          onClick={() => {
            navigate("/overview");
            window.scrollTo({ top: 0, left: 0 });
          }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <LogoImg
            sx={{ mr: 2, color: "white" }}
            alt="logo"
            onClick={() => setExtraMenuOpened(true)}
            src={images.Compass}
          />
          <AccountBalanceLabel className="hover-highlight">
            РОСС
          </AccountBalanceLabel>
        </LogoSection> */}

        <LogoSection
          onClick={() => {
            navigate("/overview");
            window.scrollTo({ top: 0, left: 0 });
          }}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <img
            style={{ minWidth: "120px", maxWidth: "120px" }}
            alt="logo"
            src={images.NewLogo}
          />
        </LogoSection>

        <Box alignItems="center" display="flex" gap="24px">
          <AccountSection>
            {!isMobile && (
              <Label onClick={() => setOpen(true)} className="hover-highlight">
                ПОИСК
              </Label>
            )}
            <SearchIcon
              onClick={() => setOpen(true)}
              sx={{ marginLeft: "5px", fill: "rgba(255, 255, 255, 0.7)" }}
            />
          </AccountSection>
        </Box>
      </HeaderContent>
    </AppHeader>
  );
};

export default Header;
