import { FC } from "react";
import { Box } from "@mui/material";
import ImageButtonItem from "./ImageButtonItem";

interface Props {
  children?: React.ReactNode;
  data: any;
}

const ImageButton: FC<Props> = ({ children, data, ...props }) => {
  return (
    <Box
      mt={4}
      gap={2}
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: "12px",
        width: "100%",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {data.map((value: any) => (
        <ImageButtonItem data={value} />
      ))}
    </Box>
  );
};

export { ImageButton };
