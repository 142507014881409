import { FC } from "react";
import { Box, Link, styled, Typography } from "@mui/material";
import { ContactsFieldType } from "types/components";

const Text = styled(Typography)(({ theme }) => ({
  fontStyle: "normal",
  fontWeight: "600",
  color: theme.palette.text.primary,
  fontSize: "20px",
  lineHeight: "26px",
  maxWidth: "100%",
  lineWidth: "500px",
  textWrap: "wrap",
  textAlign: "left",
  whiteSpace: "pre-line",
  wordBreak: "break-word",
  nowrap: false,
  [theme.breakpoints.up("xs")]: {
    fontSize: "16px",
  },
  [theme.breakpoints.up("sm")]: {},
}));

interface Props {
  data: any;
}

const ContactsList: FC<Props> = ({ data }) => {
  const preparedItem = (input: any) => (
    <>
      {input?.type === ContactsFieldType.TITLE_AND_LINK && (
        <Box mt={input?.offset} display="flex" flexDirection="column" gap="0px">
          <Text>{input?.title}</Text>
          {input?.value?.map((val: any) => (
            <Link href={val.linkValue} underline="always" color="inherit">
              <Text>{val.visibleValue}</Text>
            </Link>
          ))}
        </Box>
      )}
      {input?.type === ContactsFieldType.LINK && (
        <Box mt={input?.offset}>
          {input?.value?.map((val: any) => (
            <Link href={val.linkValue} underline="always" color="inherit">
              <Text>{val.visibleValue}</Text>
            </Link>
          ))}
        </Box>
      )}
      {input?.type === ContactsFieldType.TITLE && (
        <Text mt={input?.offset}>{input?.title}</Text>
      )}
      {input?.type === ContactsFieldType.TITLE_AND_VALUE && (
        <Box mt={input?.offset} display="flex" flexDirection="column" gap="0px">
          <Text>{input?.title}</Text>
          {input?.value?.map((val: any) => (
            <Text>{val.visibleValue}</Text>
          ))}
        </Box>
      )}
    </>
  );

  return (
    <Box
      mt={0}
      gap="0px"
      display="flex"
      flexDirection="column"
      sx={{ padding: "10px", width: "100%" }}
    >
      <Box
        gap="30px"
        display="flex"
        justifyContent="center"
        sx={{
          flexDirection: { xs: "column", md: "row" },
          minHeight: "50px",
          width: "100%",
          border: "0px solid red",
        }}
      >
        <Box
          display="flex"
          sx={{
            maxWidth: { xs: "100%", md: "50%" },
            borderRadius: "10px",
            border: "1px solid grey",
            overflow: "clip",
          }}
        >
          <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data?.img} alt="" />
        </Box>
        <Box
          display="flex"
          sx={{
            maxWidth: { xs: "100%", md: "50%" },
            borderRadius: "10px",
            flexDirection: "column",
            margin: { xs: "0", md: "10px"}
          }}
        >
          {data?.fields?.map((field: any) => (
            <>{preparedItem(field)}</>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export { ContactsList };
