import { FC, useEffect, useState } from "react";
import { Box, Grid, styled, Typography } from "@mui/material";

import { useAppSelector } from "state/hooks";
import { Link } from "react-scroll";
import { SvgIconComponent } from "@mui/icons-material";

// main section
const MainSection = styled(Box)(({ theme }) => ({
  display: "flex",
  position: "relative",
  minHeight: "100px",
  maxHeight: "100px",
  width: "100%",
  //   alignItems: 'center',
  //   border: '1px solid rgba(255,255,255,0.5)',
  justifyContent: "center",
  borderRadius: "5px",
  //   boxShadow:
  //   '0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  //  // 5A687D
  overflow: "clip",
  //   transform: 'translate3d(0px, 0px, 0px)',
  //   "&:hover": {
  //     boxShadow:
  //     '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
  //     transform: 'translate3d(0px, -4px, 0px)',
  //     border: '1px solid rgba(255,255,255,0.8)',
  //    // 5A687D
  //   },
  transition: "all 0.25s ease-in-out",
  [theme.breakpoints.up("xs")]: {},
  [theme.breakpoints.up("sm")]: {},
  [theme.breakpoints.up("md")]: {},
  [theme.breakpoints.up("lg")]: {
    flexDirection: "row",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontFamily: "Open Sans",
  fontStyle: "normal",
  fontWeight: "600",
  color: theme.palette.text.primary,
  textShadow: "-1px 1px 10px rgba(0, 0, 0, 0.75)",
  fontSize: "35px",
  transition: "all 0.5s ease-in-out",
  // lineHeight: '20px',
  maxWidth: "150px",
  lineWidth: "150px",
  textWrap: "wrap",
  textAlign: "left",
  zIndex: "1",
  whiteSpace: "pre-line",
  // textWrap: 'wrap',
  wordBreak: "break-word",
  // overflow: 'break-word',
  nowrap: false,
  // textOverflow: "ellipsis",
  // overflowWrap: 'break-word',
  // wordBreak: "break-word",
  [theme.breakpoints.up("xs")]: {
    fontSize: "15px",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "15px",
  },
}));

interface Props {
  logo: SvgIconComponent;
  title: string;
}

const ProductItem: FC<Props> = ({ logo, title }) => {
  const [isMouseOnTarget, setIsMouseOnTarget] = useState<boolean>(false);
  const KeyIcon: SvgIconComponent = logo;

  const onMouseEnter = async () => {
    setIsMouseOnTarget(true);
  };

  const onMouseLeave = async () => {
    setIsMouseOnTarget(false);
  };
  return (
    <>
      <MainSection
        sx={{ border: "0px solid red" }}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          {/* <AirplanemodeActiveIcon /> */}
          <KeyIcon />
        </Box>
        <Box
          ml={2}
          display="flex"
          justifyContent="center"
          flexDirection="column"
          sx={{}}
        >
          <Title>{title}</Title>
        </Box>
      </MainSection>
    </>
  );
};

export default ProductItem;
