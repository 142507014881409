export enum ComponentsCategory {
  MAIN_TITLE,
  IMAGE_TEXT_BOX,
  TEXT_LIST_BOX,
  INFO_BOX,
  CONTACTS_BOX,
  WHIDE_IMAGE,
  IMAGE_BUTTON,
}

export interface TitleInfo {
  fontSize: string;
  fontWeight: string;
  value: string;
}

export enum ContactsFieldType {
  TITLE_AND_LINK,
  TITLE_AND_VALUE,
  TITLE,
  LINK,
}
