import { FC, Fragment, useEffect, useState } from "react";
import { Box, Grid, styled, Tab, Tabs, Typography } from "@mui/material";

import { useAppSelector } from "state/hooks";
import { icons, images } from "config/constants/assets";
import { Link } from "react-scroll";
import { MainTitle } from "components/common/Title";
import { aboutPageSections } from "config/constants/about";
import { ObjectCreator } from "components/common/Creator/ObjectCreator";
import { productsPageSections } from "config/constants/products";
import ProductCard from "components/Products/ProductCard";
import ImageGallery from "react-image-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import "./image-gallery.css";
import { Gallery } from "components/Gallery/Gallery";
import { InfoBox } from "components/InfoBox/InfoBox";
import { TitledInfoBox } from "components/TitledInfoBox/TitledInfoBox";
import { CharacteristicsTable } from "components/CharacteristicsTable/CharacteristicsTable";
import ProductItem from "components/ProductSection/ProductItem";
import Navigation from "components/Navigation/Navigation";
import RequestModal from "components/RequestModal/RequestModal";
// overview section
const OverviewSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  background: "transparent",
  alignItems: "center",
  height: "100%",
  minHeight: "600px",
  gap: "20px",
  paddingRight: "20px",
  paddingLeft: "20px",
  justifyContent: "center",
  marginBottom: "50px",
  maxWidth: "1200px",

  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
  },
}));

// overview section
const MainSection = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  // gap: '20px',
  background: "rgba(25,54,82,1.0)",
  boxShadow:
    "0px 100px 77px rgba(0, 0, 0, 0.13), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.0989853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.0902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.0829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)",
  // 5A687D
  //   alignItems: 'center',
  minHeight: "500px",
  padding: "5px",
  "&:hover": {
    background: "rgba(25,54,82,1.0)",
    opacity: 1.0,
    // boxShadow:
    // '0px 100px 77px rgba(0, 0, 0, 0.2), 0px 25.9935px 52.3711px rgba(0, 0, 0, 0.189853), 0px 10.1608px 47.2956px rgba(0, 0, 0, 0.1902431), 0px 5.70356px 44.4605px rgba(0, 0, 0, 0.1829201), 0px 3.80638px 39.8596px rgba(0, 0, 0, 0.0717558), 0px 2.15748px 29.2214px rgba(0, 0, 0, 0.0505145)',
    // 5A687D
  },
  transition: "all 0.75s ease-in-out",
  borderRadius: "10px",
  overflow: "clip",
  //   justifyContent: 'center',
  position: "relative",
  [theme.breakpoints.up("xs")]: {
    flexDirection: "column",
  },
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column",
  },
}));

const ShortDescriptionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "300",
  color: theme.palette.text.primary,
  fontSize: "18px",
  lineHeight: "26px",

  [theme.breakpoints.up("xs")]: {
    marginBottom: "18px",
  },
  [theme.breakpoints.up("sm")]: {
    marginBottom: "18px",
  },
}));

const products = productsPageSections;

interface TabPanelProps {
  children?: React.ReactNode;
  indexTab: number;
  value: number;
}
function a11yProps(indexTab: number) {
  return {
    id: `simple-tab-${indexTab}`,
    "aria-controls": `simple-tabpanel-${indexTab}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, indexTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== indexTab}
      id={`simple-tabpanel-${indexTab}`}
      aria-labelledby={`simple-tab-${indexTab}`}
    >
      {value === indexTab && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ProductItemPage = () => {
  const images2 = [
    {
      original: images.Snegir,
      thumbnail: images.Snegir,
    },
    {
      original: images.Aero,
      thumbnail: images.Aero,
    },
    {
      original: images.Snegir,
      thumbnail: images.Snegir,
    },
  ];

  const [index, setIndex] = useState(-1);
  const [item, setItem] = useState<any>(undefined);
  const [navigationUrl, setNavigationUrl] = useState<any>([]);

  const currentImage = images2[index];
  const nextIndex = (index + 1) % images2.length;
  const nextImage = images2[nextIndex] || currentImage;
  const prevIndex = (index + images2.length - 1) % images2.length;
  const prevImage = images2[prevIndex] || currentImage;

  const handleClick = (indexSelected: number) => setIndex(indexSelected);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const onImgClick = async (e: any) => {
    // setIsMouseOnTarget(false)
    for (let i = 0; i < images2.length; i += 1) {
      const url = `${window.location.origin}${images2[i].original}`;

      if (url === e.target.currentSrc) {
        setIndex(i);
      }
    }
  };
  useEffect(() => {
    const a = document.createElement("a");
    const url = new URL(window.location.href);
    const res = url.pathname.split("/");
    setNavigationUrl(res);

    if (res.length > 3) {
      if (res[3].length > 0) {
        for (let i = 0; i < products.length; i += 1) {
          for (let k = 0; k < products[i].items.length; k += 1) {
            const itemLoc = products[i].items[k];
            if (itemLoc?.key === res[3]) {
              setItem(itemLoc);
              console.log(itemLoc);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.href]);

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const styles = {
    default_tab: {
      color: "white",
      fontWeight: 400,
    },
    active_tab: {
      color: "orange",
    },
  };
  return (
    <OverviewSection mt={0} key="About Page">
      {/* <img alt='' src={images.Aero} style={{position: 'absolute', right: 0, bottom: 0}}/> */}
      <MainSection key="Company Section">
        <Navigation data={navigationUrl} />
        <Grid
          mt={2}
          container
          spacing={2}
          sx={{
            width: "100%",
            height: "100%",
            border: "0px solid blue",
            maxHeight: "500px",
            padding: "10px",
          }}
        >
          <Grid item xs={12} md={6} sx={{ minHeight: "200px" }}>
            {/*  */}
            {/* <Box display='flex' flexDirection='row' sx={{width: '100%', height: '100%', border: '1px solid grey'}}>
                            <Gallery images={images2}/>
                        </Box> */}
            <ImageGallery
              showIndex={!!true}
              onClick={(e: any) => {
                onImgClick(e);
              }}
              thumbnailPosition="bottom"
              showFullscreenButton
              useBrowserFullscreen={!!true}
              items={item?.images ? item.images : []}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              display="flex"
              gap="10px"
              flexDirection="column"
              sx={{ width: "100%", height: "100%", border: "0px solid blue" }}
            >
              <MainTitle textAlign="left">{item?.title}</MainTitle>
              <ShortDescriptionTitle>
                {item?.shortDescriprion}
              </ShortDescriptionTitle>
              <Grid container sx={{ width: "100%" }}>
                {item?.keyNotes.map((val: any) => (
                  <Grid item xs={6} md={6}>
                    <ProductItem logo={val.logo} title={val.title} />
                  </Grid>
                ))}
              </Grid>
              {/* <Box ml={0}> */}
              <RequestModal pageTitle={item?.title}/>
              {/* </Box> */}
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          sx={{
            mt: { xs: "100px", sm: "150px", md: "16px" },
            width: "100%",
            height: "100%",
            border: "0px solid blue",
            // maxHeight: "500px",
            padding: "2px",
          }}
        >
          <Grid item xs={12} md={12} sx={{ minHeight: "200px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab
                  style={styles.default_tab}
                  label="Описание"
                  {...a11yProps(0)}
                />
                {item?.characteristics.length > 0 && (
                  <Tab
                    style={styles.default_tab}
                    label="Характеристики"
                    {...a11yProps(1)}
                  />
                )}
                {item?.external?.length > 0 && (
                  <Tab
                    style={styles.default_tab}
                    label="Item Three"
                    {...a11yProps(2)}
                  />
                )}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} indexTab={0}>
              <Box display="flex" flexDirection="column">
                <>
                  {item?.fullDescription &&
                    item?.fullDescription.map((data: any) => (
                      <TitledInfoBox data={data} />
                    ))}
                </>
              </Box>
            </CustomTabPanel>
            <CustomTabPanel value={value} indexTab={1}>
              <>
                {item?.characteristics && (
                  <CharacteristicsTable data={item.characteristics} />
                )}
              </>
            </CustomTabPanel>
            <CustomTabPanel value={value} indexTab={2}>
              Item Three
            </CustomTabPanel>
          </Grid>
        </Grid>
        {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
            mainSrc={currentImage.original}
            imageTitle=""
            mainSrcThumbnail={currentImage.thumbnail}
            nextSrc={nextImage.original}
            nextSrcThumbnail={nextImage.thumbnail}
            prevSrc={prevImage.original}
            prevSrcThumbnail={prevImage.thumbnail}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </MainSection>
    </OverviewSection>
  );
};

export default ProductItemPage;
