import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import ProductItemCard from "components/Products/ProductItemCard";
// import searchInArray from "./searchUtils";
import {
  searchInArray,
  searchInPossibilitysItem,
  searchInProductsItem,
} from "./SearchMethods";
import GoalSearchCard from "./GoalSearchCard";

interface SearchProps {
  productsData: any;
  possibilitysData: any;
  query: string;
}

const Searcher: React.FC<SearchProps> = ({
  productsData,
  possibilitysData,
  query,
}) => {
  const filteredProducts = productsData.flatMap((group: any) =>
    group.items.filter((item: any) => searchInProductsItem(item, query))
  );

  // Function for set search filter
  // const filteredPossibilitys = possibilitysData.filter((item: any) =>
  //   searchInPossibilitysItem(item, query)
  // );

  const filteredPossibilitys = searchInArray(possibilitysData, query); // Search through all string fields, including nested fields

  return (
    <div>
      {query && query !== " " && (
        <div>
          {filteredProducts.length > 0 && (
            <Box
              mt={4}
              mb={6}
              sx={{
                background: "transparent",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography mb={3} fontSize="26px" align="center">
                ПРОДУКЦИЯ
              </Typography>
              <Grid
                container
                // spacing={5}
                gap={4}
                justifyContent="center"
                sx={{
                  width: "100%",
                  background: "transparent",
                  border: "0px solid blue",
                }}
              >
                {filteredProducts.map((entry: any) => (
                  <Grid item key={entry.title} xs={12} md={4}>
                    <ProductItemCard data={entry} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
          {filteredPossibilitys.length > 0 && (
            <Box
              mt={4}
              mb={6}
              sx={{
                background: "transparent",
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Typography mb={3} fontSize="26px" align="center">
                ВОЗМОЖНОСТИ
              </Typography>
              <Grid container gap={4} display="flex" justifyContent="center">
                {filteredPossibilitys.map((entry: any) => (
                  <Grid
                    display="flex"
                    justifyContent="center"
                    item
                    key={entry.title}
                    xs={12}
                    md={4}
                  >
                    <GoalSearchCard data={entry} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </div>
      )}
      {query &&
        (query === " " ||
          (filteredProducts.length === 0 &&
            filteredPossibilitys.length === 0)) && (
          <Typography mt={3} align="center" fontSize="18px">
            По вашему запросу ничего не найдено
          </Typography>
        )}
    </div>
  );
};

export default Searcher;
