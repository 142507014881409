import React, { FC, useEffect, useState } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'state/hooks';
import MainLayout from 'layouts/MainLayout';
import CompanyPage from 'pages/Company';
import ProductsPage from 'pages/Products';
import ProductItemCard from 'components/Products/ProductItemCard';
import ProductItemPage from 'pages/ProductItem';
import ProductItems from 'pages/ProductItems';
import GalleryPage from 'pages/Gallery';
import PossibilitysPage from 'pages/Possibilitys';
import PossibilityItemPage from 'pages/PossibilityItem';
import ContactsPage from 'pages/Contacts';
import LicensesPage from 'pages/Licenses';

const OverviewPage = React.lazy(() => import('./pages/Overview'));

const Router: FC = () => {
  const { selectedChainId, isWalletChecked } = useAppSelector((state) => state.chain);

  const dispatch = useAppDispatch();
  const [counter2, setCounter2] = useState(0);

  const [mainLoaded, setMainLoaded] = useState(false);

  const fetchData = async () => {
    try{
      if( isWalletChecked && !mainLoaded ){
        setMainLoaded(true);
      }
    }
    catch(err) {
      console.log(err)
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId, isWalletChecked]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChainId]);

  useEffect(() => {
    // fetch farm data
    const interval = setInterval(() => {
      // if(  ){
        setCounter2(counter2 + 1);
      // }else {
      //     fetchUserData();
      //     clearInterval(interval);
      // }
    }, 60000);
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ selectedChainId, counter2, isWalletChecked]);

  // useEffect(() => {
  //   if (!selectedChainId) {
  //         window.localStorage.setItem("selectedChainId", String(DEFAULT_ACTIVE_CHAIN_ID));
  //        dispatch(setSelectedChainId(String(DEFAULT_ACTIVE_CHAIN_ID)));
  //    }
  //   const interval = setInterval(() => {
  //     setCounter(counter + 1);
  //     fetchData();
  //   }, 60000);
  //   return () => clearInterval(interval);
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [counter, selectedChainId, isWalletChecked])

  const routes = useRoutes([
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { index: true, element: <Navigate replace to="/overview" /> },
        { path: '/overview', element: <OverviewPage /> },
        { path: '/company', element: <CompanyPage /> },
        { path: '/products', element: <ProductsPage /> },
        { path: '/products/:items', element: <ProductItems /> },
        { path: '/products/:items/:item', element: <ProductItemPage /> },
        { path: '/possibilitys', element: <PossibilitysPage /> },
        { path: '/possibilitys/:item', element: <PossibilityItemPage /> },
        { path: '/gallery', element: <GalleryPage /> },
        { path: '/company/contacts', element: <ContactsPage /> },
        { path: '/company/licenses', element: <LicensesPage /> },

      ],
    },

    { path: '*', element: <Navigate replace to="/" /> },
  ]);

  return routes;
};

export default Router;
